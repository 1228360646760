import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`This document defines and describes certificate authority core concepts that are integral to the design
and practical usage of `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`.`}</p>
    <p>{`Readers who are not yet familiar with public key infrastructure (PKI) and
certificate management may want to check out...`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/blog/everything-pki/"
        }}>{`Everything you should know about certificates and PKI but are too afraid to ask`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://smallstep.com/blog/use-tls/"
        }}>{`The case for using TLS everywhere`}</a></li>
    </ul>
    <h2>{`Overview`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#online-and-offline-x509-certificate-authority"
        }}>{`Online X.509 Certificate Authority`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#provisioners"
        }}>{`Provisioners`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#policies"
        }}>{`Certificate Issuance Policies`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#templates"
        }}>{`Certificate Templates`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#active-vs-passive-revocation"
        }}>{`Active vs. Passive Revocation`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#other-operational-modes"
        }}>{`Other Operational Modes`}</a></li>
    </ul>
    <h2>{`Online X.509 Certificate Authority`}</h2>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` is an online certificate authority, meaning it runs as a server
on the network and accepts certificate requests.`}</p>
    <h2>{`Provisioners`}</h2>
    <p>{`Provisioners are methods of proving an entity's identity to the CA prior to getting a new certificate.
The CA needs proof that an entity that is requesting a new certificate is who they say they are.
Once a provisioner authenticates an entity's identity, it then issues the entity a bearer token to submit to the CA, along with a `}<a parentName="p" {...{
        "href": "https://smallstep.com/blog/everything-pki.html#certificate-signing-requests",
        "title": "CSR"
      }}>{`certificate
signing
request`}</a>{` (CSR), to obtain the new certificate.`}</p>
    <p>{`The details of how a provisioner interacts with an entity (machines or people) and the CA vary by provisioner type.
Smallstep supports a number of provisioner types including:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#acme"
        }}>{`ACME protocol`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#oauthoidc-single-sign-on"
        }}>{`OAuth/OIDC Single Sign-on`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#aws"
        }}>{`AWS Instance Identity Documents`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#gcp"
        }}>{`Google Cloud Instance Identity Tokens`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#azure"
        }}>{`Azure Instance Metadata Service`}</a>{` `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#k8ssa-kubernetes-service-account"
        }}>{`Kubernetes Service Account Tokens`}</a>{` `}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#scep"
        }}>{`SCEP protocol`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/provisioners#jwt"
        }}>{`JWT for building custom integrations`}</a></li>
    </ul>
    <h2>{`Active vs. passive revocation`}</h2>
    <p>{`There are two certificate revocation methods: active vs. passive.
Private PKIs like `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` uses passive revocation by default.
Passive revocation doesn't use Certificate Revocation List (CRL) and Online Certificate Signing Protocol (OCSP) like the Web PKI you may be familiar with. `}</p>
    <p>{`To passively revoke a certificate means to block its renewal at the CA.
This eliminates the additional network request that occurs when using a web PKI because the certificate just expires by itself.
Unlike active revocation, certificates cannot be immedietely revoked.
Therefore, certificates should have a shorter lifetime to reduce the value of a key that has been exfiltrated.`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/passive-revocation.png",
        "alt": "passive revocation diagram"
      }}></img></p>
    <h2>{`Policies`}</h2>
    <p>{`With certificate issuance policies administrators can configure what Subjects, SANs and Principals the CA is allowed to sign.
An example of a policy is to only allow (strict) subdomains of `}<inlineCode parentName="p">{`internal.example.com`}</inlineCode>{`, which would be encoded as `}<inlineCode parentName="p">{`*.internal.example.com`}</inlineCode>{`.`}</p>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/policies"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode>{` policy`}</a>{` page to learn how certificate issuance policies work and how they can be configured.`}</p>
    <h2>{`Templates`}</h2>
    <p>{`People use private CAs for all sorts of things, in many different contexts:
web apps, mobile apps, code signing, cloud VM instances, SSH, IoT devices, etc.
So `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` must be flexible enough to handle a wide variety of flows.`}</p>
    <p>{`X.509 and SSH certificate templates open up these possibilities.
With certificate templates, you can do things like:`}</p>
    <ul>
      <li parentName="ul">{`Add custom SANs or extensions to X.509 certificates`}</li>
      <li parentName="ul">{`Make longer certificate chains, with multiple intermediate CAs`}</li>
      <li parentName="ul">{`Use SSH `}<inlineCode parentName="li">{`force-command`}</inlineCode>{` or `}<inlineCode parentName="li">{`source-address`}</inlineCode>{` extensions`}</li>
      <li parentName="ul">{`Add conditionals around a certificate's parameters, and fail if they are not met`}</li>
      <li parentName="ul">{`Enrich a certificate with data `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/webhooks"
        }}>{`from a webhook`}</a></li>
    </ul>
    <p>{`Visit the `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/templates"
      }}><inlineCode parentName="a">{`step-ca`}</inlineCode>{` templates`}</a>{` page to learn how to use templates.`}</p>
    <h2>{`Other operational modes`}</h2>
    <p>{`Aside from being an online Certificate Authority, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` has a couple of non-standard modes of operation:
Registration Authority (RA) mode, and offline mode.`}</p>
    <h3>{`Registration Authority (RA) mode`}</h3>
    <p><inlineCode parentName="p">{`step-ca`}</inlineCode>{` can operate in Registration Authority (RA) mode.
An RA is a server that acts as an authentication layer for an upstream signing CA.`}</p>
    <p>{`RA mode lets you separate certificate request authentication (the RA) from certificate signing operations (the CA).
This operational mode centralizes key management:
a single CA can serve several RAs.`}</p>
    <p>{`When in RA mode, `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` can peer with three kinds of upstream CA:`}</p>
    <ul>
      <li parentName="ul">{`Another `}<inlineCode parentName="li">{`step-ca`}</inlineCode>{` server ("StepCAS" mode)`}</li>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "https://cloud.google.com/certificate-authority-service/"
        }}>{`Google CloudCAS`}</a>{` CA`}</li>
      <li parentName="ul">{`A `}<a parentName="li" {...{
          "href": "https://www.hashicorp.com/products/vault"
        }}>{`Hashicorp Vault`}</a>{` CA`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/graphics/stepcas-ra-mode.png",
        "alt": "Example PKI topology with StepCAS RA Mode"
      }}></img></p>
    <p>{`To create an RA-based PKI topology, see our `}<a parentName="p" {...{
        "href": "https:/prof.infra.smallstep.com/docs/step-ca/registration-authority-ra-mode"
      }}>{`Registration Authority (RA) Mode`}</a>{` documentation.`}</p>
    <h3>{`Offline Mode`}</h3>
    <p>{`Sometimes it's useful to access a local CA offline, without running the `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` server.
For this purpose, the `}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI can be used in `}<em parentName="p">{`offline mode`}</em>{` (with the `}<inlineCode parentName="p">{`--offline`}</inlineCode>{` flag).
Offline mode uses the configuration, database, certificates, and keys of an existing `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` installation.`}</p>
    <p>{`This table shows some of the feature differences between an online `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{` server, `}<inlineCode parentName="p">{`step`}</inlineCode>{` CLI in offline mode, and the `}<a parentName="p" {...{
        "href": "https://smallstep.com/docs/step-cli/basic-crypto-operations#run-an-offline-x509-certificate-authority"
      }}><inlineCode parentName="a">{`step certificate`}</inlineCode>{` subcommand`}</a>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "/graphics/cas-three-ways.png",
        "alt": null
      }}></img></p>
    <h3>{`Example: Offline Mode`}</h3>
    <p>{`Let's create a certificate without `}<inlineCode parentName="p">{`step-ca`}</inlineCode>{`:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell-session",
        "metastring": "nocopy",
        "nocopy": true
      }}>{`$ step ca init --name "Local CA" --provisioner admin --dns localhost --address ":443"
$ step ca certificate --offline foo.smallstep.com foo.crt foo.key
`}</code></pre>
    <h2>{`Next Steps`}</h2>
    <ul>
      <li parentName="ul">{`Read the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/step-ca/getting-started"
        }}>{`Getting Started guide`}</a>{` to set up a CA and get your first certificate.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      